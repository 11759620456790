import React, {Fragment} from 'react';

const Description = ({cards}) => {
  const DescriptionBox = (cards) => {
    let description = [];
    cards.cards.forEach((card) => {
      description.push(
        card.customerName +
          ' - ' +
          card.invoiceNumber +
          ' - ' +
          card.designs.join(' & ')
      );
    });

    return (
      <span
        dangerouslySetInnerHTML={{__html: description.join('<br />')}}
      ></span>
    );
  };

  return (
    <Fragment>
      <h5 className='text-muted'>Generated Description:</h5>
      <div className='card mb-4'>
        <div className='card-body'>
          {<DescriptionBox cards={cards}></DescriptionBox>}
        </div>
        <div className='card-footer'>
          Silakan cek ulang deskripsi sebelum digunakan di Xero.
        </div>
      </div>
    </Fragment>
  );
};

export default Description;
