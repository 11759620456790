import React, {useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {findCards} from '../../actions/cards';

const Form = ({findCards}) => {
  const [formData, setFormData] = useState({
    customerRef: '',
    cardsData: '',
  });

  const {customerRef} = formData;

  const onChange = e =>
    setFormData({...formData, [e.target.name]: e.target.value});

  const onSubmit = e => {
    e.preventDefault();
    findCards(formData);
  };

  return (
    <Fragment>
      <form onSubmit={e => onSubmit(e)}>
        <div className='form-group row'>
          <div className='input-group input-group-lg'>
            <input
              type='text'
              name='customerRef'
              className='form-control'
              id='customerRef'
              placeholder='Customer Reference'
              value={customerRef}
              onChange={e => onChange(e)}
            />
            <div className='input-group-append'>
              <button type='submit' className='btn btn-lg btn-success'>
                Find
              </button>
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

Form.propTypes = {
  findCards: PropTypes.func.isRequired,
};

export default connect(null, {findCards})(Form);
