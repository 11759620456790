import React, {Fragment} from 'react';

const CardItem = ({cards}) => {
  return (
    <Fragment>
      <h5 className='text-muted'>Trello Cards</h5>
      {cards.map(card => (
        <Fragment key={card.id}>
          <div className='card mb-4'>
            <div className='card-header'>{card.title}</div>
            <div className='card-body'>
              <h6>
                <span className='font-weight-bold'>Customer Name:</span>{' '}
                {card.customerName}
              </h6>
              <h6>
                <span className='font-weight-bold'>Invoice Number:</span>{' '}
                {card.invoiceNumber}
              </h6>
              <h6>
                <span className='font-weight-bold'>Designs: </span>{' '}
              </h6>
              <p>{card.designs.join(' & ')}</p>
              <h6>
                <span className='font-weight-bold'>Description:</span>
              </h6>
              <p className='card-text text-truncate'>{card.desc}</p>
              <a
                href={card.url}
                rel='noopener noreferrer'
                target='_blank'
                className='btn btn-primary'
              >
                Go to card
              </a>
            </div>
          </div>
        </Fragment>
      ))}
    </Fragment>
  );
};

export default CardItem;
