import React, {useState, Fragment, useEffect} from 'react';
import {connect} from 'react-redux';

import CardsItem from './CardsItem';
import Description from './Description';
import Uploader from './Uploader';
import Form from './Form';

const Dashboard = ({cards: {cards, loading}}) => {
  useEffect(() => {
    if (cards.length > 0) {
      setFormData({
        cardsData: cards,
      });
    }
  }, [cards, loading]);

  const [formData, setFormData] = useState({
    cardsData: '',
  });

  const {cardsData} = formData;

  return (
    <Fragment>
      <div className='card border-light mb-4'>
        <div className='card-header'>
          <h5 className='card-title'>Trello Card Finder</h5>
          <h6 className='card-subtitle mb-2  text-muted'>
            Upload CMYKHUB invoice untuk menemukan card yang berhubungan dengan
            invoice tersebut.
          </h6>
        </div>
        <div className='card-body'>
          <Uploader></Uploader>
          {/* <Form></Form> */}
        </div>
      </div>
      <div className='card border-light mb-4'>
        <div className='card-header'>
          <h6 className='card-subtitle mb-2  text-muted text-center'>
            Atau gunakan form pencarian berikut
          </h6>
        </div>
        <div className='card-body'>
          <Form></Form>
        </div>
      </div>
      {cardsData.length > 0 ? (
        <Description key='description' cards={cardsData} />
      ) : (
        ''
      )}
      {cardsData.length > 0 ? (
        <CardsItem key='CardsItem' cards={cardsData} />
      ) : (
        ''
      )}
    </Fragment>
  );
};

Dashboard.propTypes = {};

const mapStateToProps = (state) => ({
  cards: state.cards,
});

export default connect(mapStateToProps, {})(Dashboard);
