import axios from 'axios';
import {GET_CARDS, CARDS_ERROR} from './types';
import {setAlert} from './alert';
import setHeaderUploader from '../utils/setHeaderUploader';
import config from '../config';

export const uploadInvoice = formData => async dispatch => {
  try {
    const reqConfig = setHeaderUploader();
    const res = await axios.post(
      config.BACKEND_URL + 'api-trello/upload',
      formData,
      reqConfig
    );
    dispatch({
      type: GET_CARDS,
      payload: res.data.data,
    });
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: CARDS_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};
