import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';

import Header from './components/layout/Header';
import VerticalNav from './components/layout/VerticalNav';
import Landing from './components/Landing';
import Alert from './components/layout/Alert';
import PrivateRoute from './components/routing/PrivateRoute';

import Dashboard from './components/dashboard/Dashboard';

import { Provider } from 'react-redux';
import store from './store';

if (window.location.href.includes('?tkn')) {
  let token = window.location.href.split('?tkn=');
  token = token[1];
  localStorage.setItem('token', token);
}

if (localStorage.token) {
  setAuthToken(localStorage.token);
  store.dispatch(loadUser());
} else {
  // window.location.href = config.AUTH_URL + '?src=' + window.location.href;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);
  const classes = useStyles();

  return (
    <Provider store={store}>
      <Router>
        <Fragment>
          <div className={classes.root}>
            <CssBaseline />
            <Header />
            <VerticalNav />
            <main className={classes.content}>
              <Toolbar />
              <Alert />
              <PrivateRoute exact path='/' component={Landing} />
              <Switch>
                <PrivateRoute exact path='/dashboard' component={Dashboard} />
              </Switch>
            </main>
          </div>
        </Fragment>
      </Router>
    </Provider>
  );
};

export default App;
