import React, {useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {uploadInvoice} from '../../actions/files';

const Uploader = ({uploadInvoice}) => {
  const [formData, setFormData] = useState({
    selectedFile: null,
  });
  const {selectedFile} = formData;

  const onFileChange = e => {
    setFormData({...formData, selectedFile: e.target.files[0]});
  };
  const onClick = e => {
    const data = new FormData();
    data.append('file', selectedFile);
    uploadInvoice(data);
  };
  return (
    <Fragment>
      <div className='form-group row text-muted'>
        <div className='offset-md-4 col-md-3'>
          <div className='form-group files'>
            <input
              type='file'
              name='file'
              className='form-control'
              onChange={e => onFileChange(e)}
            />
          </div>
          <button
            type='button'
            className='btn btn-block btn-success'
            onClick={e => onClick(e)}
          >
            Find
          </button>
        </div>
      </div>
    </Fragment>
  );
};

Uploader.propTypes = {
  uploadInvoice: PropTypes.func.isRequired,
};

export default connect(null, {uploadInvoice})(Uploader);
