export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const GENERATE_LINK_SUCCESS = 'GENERATE_LINK_SUCCESS';
export const GENERATE_LINK_FAIL = 'GENERATE_LINK_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

export const GET_CARDS = 'GET_CARDS';
export const CARDS_ERROR = 'CARDS_ERROR';
