import axios from 'axios';
import {GET_CARDS, CARDS_ERROR} from './types';
import {setAlert} from './alert';
import setHeader from '../utils/setHeader';
import config from '../config';

export const findCards = formData => async dispatch => {
  try {
    const reqConfig = setHeader();
    const res = await axios.post(
      config.BACKEND_URL + 'api-trello/find',
      formData,
      reqConfig
    );
    dispatch({
      type: GET_CARDS,
      payload: res.data.data,
    });
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: CARDS_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};
