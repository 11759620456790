import { GET_CARDS, CARDS_ERROR } from '../actions/types';

const initialState = {
  card: {},
  cards: [],
  loading: true,
  error: {},
};

export default function auth(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_CARDS:
      return {
        ...state,
        card: {},
        cards: payload,
        loading: false,
      };
    case CARDS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
