import axios from 'axios';
import {setAlert} from './alert';
import {
  GENERATE_LINK_SUCCESS,
  GENERATE_LINK_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
} from './types';
import setAuthToken from '../utils/setAuthToken';
import config from '../config';

// Load User
export const loadUser = () => async dispatch => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get(config.AUTH_URL + 'auth/me');
    dispatch({type: USER_LOADED, payload: res.data.data});
  } catch (error) {
    dispatch({type: AUTH_ERROR});
  }
};

//generate link to login
export const generate = ({slug, email, password_hash}) => async dispatch => {
  const req_config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({slug, email, password_hash});
  try {
    const res = await axios.post(
      config.CLOCKIN_URL + '/auth/register',
      body,
      req_config
    );
    dispatch({
      type: GENERATE_LINK_SUCCESS,
      payload: res.data.data,
    });
    dispatch(loadUser());
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      console.log(errors);
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }
    dispatch({
      type: GENERATE_LINK_FAIL,
    });
  }
};

//login user
export const login = ({slug, email, password_hash}) => async dispatch => {
  const req_config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({email, password_hash});
  try {
    const res = await axios.post(
      config.CLOCKIN_URL + '/auth/' + slug,
      body,
      req_config
    );
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data.data,
    });
    dispatch(loadUser());
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      console.log(errors);
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

// LOGOUT
export const logout = () => dispatch => {
  dispatch({type: LOGOUT});
};
